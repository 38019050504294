import { Geocoder } from "@areahub/lib"

export const geocoder = new Geocoder()

export async function fetchResults(query: string): Promise<any> {
  const client = new Geocoder()
  const res = await client.geocode(query, {
    country: "us",
    types: "postcode,district,place,locality,neighborhood,address,place,poi",
    worldview: "us",
  })
  return res.features
}

export async function fetchInputResults(query: string): Promise<any> {
  const client = new Geocoder()
  const res = await client.geocode(query, {
    country: "us",
    types: "postcode,district,place,locality,neighborhood,address,place,poi",
    worldview: "us",
  })
  return res.features
}
