// @ts-nocheck
import { CookieBanner, LoadingContext } from "@areahub/components"
import { Fetcher, processEnv } from "@areahub/lib"
import { GlobalStyles, theme } from "@areahub/theme"
import { ChakraProvider } from "@chakra-ui/react"
import { SearchModal } from "components/SearchModal"
import { defaultSeo } from "configs/seo"
import { AnimatePresence } from "framer-motion"
import "mapbox-gl/dist/mapbox-gl.css"
import { DefaultSeo } from "next-seo"
import { AppProps } from "next/app"
import { Router } from "next/router"
import NProgress from "nprogress"
import "nprogress/nprogress.css"
import { RecoilRoot } from "recoil"
import { SWRConfig } from "swr"
import { laggy } from "utils/middleware/laggy"
import { localStorageProvider } from "utils/store/localStorage"
import LogRocket from 'logrocket';

NProgress.configure({ showSpinner: false })
Router.events.on("routeChangeStart", () => NProgress.start())
Router.events.on("routeChangeComplete", () => NProgress.done())
Router.events.on("routeChangeError", () => NProgress.done())

// Tracking
if (processEnv.shouldTrack) {
  // Track user actions with logrocket
  LogRocket.init('cmfkie/wildfire-side-door');
  // Track client-side page views with Segment
  Router.events.on("routeChangeComplete", (url) => {
    typeof window !== "undefined" && window?.analytics?.page(url)
  })
}

function WildfireSideDoor({ Component, pageProps, router }: AppProps) {
  return (
    <ChakraProvider resetCSS theme={theme}>
      <GlobalStyles />
      <RecoilRoot>
        <DefaultSeo {...defaultSeo} />
        <SWRConfig
          value={{
            fetcher: Fetcher,
            provider: localStorageProvider,
            use: [laggy],
          }}
        >
          <AnimatePresence
            exitBeforeEnter
            initial={false}
            onExitComplete={() => window.scrollTo(0, 0)}
          >
            <Component {...pageProps} key={router.route} />
            <SearchModal />
            <LoadingContext key={"loading-ctx"} />
            <CookieBanner />
          </AnimatePresence>
        </SWRConfig>
      </RecoilRoot>
    </ChakraProvider>
  )
}

export default WildfireSideDoor
