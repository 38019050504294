import { DefaultValue } from "recoil"

export const hasWindow = typeof window !== "undefined" ? true : false
export const localStorageEffect = (key: string) => ({
  setSelf,
  onSet,
}: any) => {
  if (hasWindow) {
    const savedValue = window?.localStorage.getItem(key)
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue))
    }

    onSet((newValue: any) => {
      if (newValue instanceof DefaultValue) {
        window?.localStorage.removeItem(key)
      } else {
        window?.localStorage.setItem(key, JSON.stringify(newValue))
      }
    })
  }
}

export function localStorageProvider() {
  // When initializing, we restore the data from `localStorage` into a map.
  const initialAppCache = hasWindow
    ? JSON.parse(window?.localStorage?.getItem("app-cache") || "[]")
    : []
  const map = new Map(initialAppCache)

  // Before unloading the app, we write back all the data into `localStorage`.
  hasWindow &&
    window?.addEventListener("beforeunload", () => {
      const persistedAppCache = JSON.stringify(Array.from(map.entries()))
      window?.localStorage.setItem("app-cache", persistedAppCache)
    })

  // We still use the map for write & read for performance.
  return map
}

export const setCacheItem = (key: string, object: any) => {
  if (hasWindow) {
    const item = JSON.stringify(object)
    return window?.localStorage.setItem(key, item)
  }
}
