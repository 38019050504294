import { createDefaultSeo, defaultSeo as libdefaultSeo } from "@areahub/lib"
import { ErrorContent } from "components/Errors/ErrorContent"
import { NextSeoProps } from "next-seo"

const title = "Wildfires by AreaHub"
const description = "Get the the latest on wildfires in your vicinity."

export const defaultSeo = createDefaultSeo({
  title,
  description,
  openGraph: {
    ...libdefaultSeo.openGraph,
    images: [
      {
        url: "https://media.graphcms.com/9SMDzr3VSJi0HyfA6qmc",
        width: 1140,
        height: 600,
      },
    ],
    title,
    description,
    site_name: "Wildfires by AreaHub",
  },
})

export const pageSeo: { [key: string]: (props?: any) => NextSeoProps } = {
  now: () => ({
    title: "Live Wildfire Map by Areahub",
    openGraph: {
      ...defaultSeo.openGraph,
      title: "Live Wildfire Map by Areahub",
    },
  }),
  report: ({ place_name }) => {
    const title = !!place_name
      ? `Wildfires near ${place_name} - Wildfire Report by Areahub`
      : "Wildfire Report by Areahub"
    return {
      title,
      openGraph: {
        ...defaultSeo.openGraph,
        title,
      },
    }
  },
  fireId: ({ fireName, description }) => ({
    title: `${fireName || "Unnamed fire"} - Wildfires by Areahub`,
    description,
    openGraph: {
      ...defaultSeo.openGraph,
      title: `${fireName || "Unnamed fire"} - Wildfires by Areahub`,
      description,
    },
  }),
  404: () => ({
    title: ErrorContent[404].header,
    description: ErrorContent[404].message,
    openGraph: {},
  }),
  500: () => ({
    title: ErrorContent[500].header,
    description: ErrorContent[500].message,
    openGraph: {},
  }),
}
