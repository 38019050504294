import { useRecoilDisclosure, useSearchPlaces } from "@areahub/components"
import stringify from "fast-json-stable-stringify"
import { fetchInputResults, fetchResults } from "lib/geocoder"
import { useRouter } from "next/router"
import { atom } from "recoil"
import { Feature } from "../../../../@types/mapbox-api"
import { useLoadingCtx } from "./use-loading-ctx"
export const globalSearch = atom({
  key: "globalSearch",
  default: false,
})

export const useGlobalSearch = () => useRecoilDisclosure(globalSearch)

export const useSearchPlaceParams = () => {
  const router = useRouter()
  const getQueryParams = (feature: Feature) => ({
    q: stringify({
      coordinates:
        feature.geometry?.type === "Point"
          ? feature.geometry.coordinates
          : feature.center,
      place_name: feature.place_name,
    }),
  })

  const onSubmit = (feature: Feature) => {
    router.push({
      pathname: "/report/[q]",
      query: getQueryParams(feature),
    })
  }
  const onHoverItem = (feature: Feature) =>
    router.prefetch(`/report/${getQueryParams(feature)}`)
  return {
    router,
    getQueryParams,
    onSubmit,
    onHoverItem,
  }
}

export const useInputSearchPlaces = () => {
  const { onSubmit, onHoverItem } = useSearchPlaceParams()
  const params = useSearchPlaces(fetchInputResults, onSubmit, onHoverItem)

  return params
}

export const useGlobalSearchPlaces = () => {
  const { triggerLoading } = useLoadingCtx()
  const { onSubmit, onHoverItem } = useSearchPlaceParams()
  const customModal = useGlobalSearch()
  const onSearchSubmit = (feature: Feature) => {
    onSubmit(feature)
    triggerLoading([`Searching for wildfires near`, `${feature.place_name}`])
  }
  const params = useSearchPlaces(
    fetchResults,
    onSearchSubmit,
    onHoverItem,
    customModal
  )

  return params
}
