import { loadingCtx, LoadingCtxType } from "@areahub/components"
import { useRecoilState } from "recoil"
export const useLoadingCtx = () => {
  const [ctx, setLoadingCtx] = useRecoilState<any>(loadingCtx)
  const triggerLoading = (message: string[], props?: Partial<LoadingCtxType>) =>
    setLoadingCtx({
      ...ctx,
      props: {
        ...ctx.props,
        textSx: {
          color: "red.500",
        },
        message,
      },
      lottieAnimation: "FIRE",
      isOpen: true,
      ...props,
    })
  return { triggerLoading }
}
