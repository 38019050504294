export const ErrorContent: {
  [code: number]: {
    header?: string
    message: string
  }
} = {
  400: {
    header: "Bad Request",
    message: "The page you requested could not be found",
  },
  404: {
    header: "Oops! Page Not Found",
    message:
      "We searched high and low but couldn't find the pages you're looking for",
  },
  500: {
    header: "Sorry! Something Went Wrong",
    message: "Internal Server Error",
  }
}